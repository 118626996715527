import React from 'react';
import { Link } from 'gatsby';
import { img, alt, isBe, imgBrand } from '../../../../lib/brand';
import LinkButton from '../../../LinkButton/';
import StaticText from '../../../StaticText';
import OneTrustButton from '../../../OneTrustButton';
import { footerNav } from '../../../../lib/gtm';
import styles from './styles.module.less';

var facebookIcon = img(`${isBe ? 'facebookIcon.svg' : 'facebookIconBlack.svg'}`);
var xIcon = img(`${isBe ? 'xIconWhite.svg' : 'xIconBlack.svg'}`);
var instagramIcon = img(`${isBe ? 'instagramIcon.svg' : 'instagramIconBlack.svg'}`);
var linkedinIcon = img(`${isBe ? 'linkedinIcon.svg' : 'linkedinIconBlack.svg'}`);
var youtubeIcon = img(`${isBe ? 'youtubeIcon.svg' : 'youtubeIconBlack.svg'}`);

var equalHousingLogo = img(isBe ? 'Equal-Housing-Lender.png' : 'Equal-Housing-Lender-Black.png', 'Layout');
var BEHouseMark = isBe ? img('BE-House-Icon-Registration-Mark.png', 'Layout') : imgBrand('rf_logo_black.png', 'Header');
var BELogoNoText = isBe ? img('BE-NO-HOME-LOANS-TEXT.png', 'Layout') : null;

const Footer = ({ staticTextItems, buttonRef }) => {
   const phoneNumber = `${isBe ? '800.BAY.3703' : '800.229.3703'}`;
   return (
      <div className={styles.footerContain}>
         <footer className={styles.siteFooter}>
            <div className={styles.col1}>
               <h3>Bay Equity LLC</h3>
               <div>
                  <span> NMLS ID# 76988</span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/76988">
                        NMLS Consumer Access
                     </a>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/Bay%20Equity%20Licensing%20Disclosures.pdf">
                        Licensing Disclosures
                     </a>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/Bay%20Equity%20Privacy%20Policy.pdf">
                        Privacy Online Statement
                     </a>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/California%20Privacy%20Notice.pdf">
                        CA Privacy Policy
                     </a>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/Bay%20Equity_Employee%20Privacy%20Policy_Final.pdf">
                        Employee Privacy Notice
                     </a>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/Patriot%20Act%20Disclosure.pdf">
                        Patriot Act Disclosure
                     </a>
                  </span>
                  <br />
                  <span>
                     <Link to="/accessibility">Accessibility Statement</Link>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/Bay-Equity-Terms-of-Use.pdf">
                        Terms of Use
                     </a>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/Bay%20Equity%20Affiliated%20Business%20Arrangement%20Disclosure%20(AfBA).pdf">
                        Affiliated Business Arrangement Disclosure
                     </a>
                  </span>
                  <br />
                  <span>
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        data-gtm={footerNav}
                        href="https://baycdn.blob.core.windows.net/homeloans/disclosures/IL-Community-Reinvestment-Notice.pdf">
                        Illinois Community Reinvestment Notice
                     </a>
                  </span>
               </div>
            </div>
            <div className={styles.col2}>
               {isBe && (
                  <>
                     <h3>Follow us</h3>
                     <div className={styles.social}>
                        <div>
                           <a
                              href="https://facebook.com/BayEquityHomeLoans"
                              target="_blank"
                              data-gtm={footerNav}
                              rel="noopener noreferrer">
                              <img src={facebookIcon} data-gtm={footerNav} alt={alt('Facebook Social Media Icon')} />
                           </a>
                        </div>
                        <div>
                           <a
                              href="https://x.com/BayEquity"
                              target="_blank"
                              data-gtm={footerNav}
                              rel="noopener noreferrer">
                              <img src={xIcon} data-gtm={footerNav} alt={alt('X Social Media Icon')} />
                           </a>
                        </div>
                        <div>
                           <a
                              href="https://linkedin.com/company/bay-equity-llc/"
                              target="_blank"
                              data-gtm={footerNav}
                              rel="noopener noreferrer">
                              <img src={linkedinIcon} data-gtm={footerNav} alt={alt('LinkedIn Social Media Icon')} />
                           </a>
                        </div>
                        <div>
                           <a
                              href="https://youtube.com/user/bayequityloans"
                              target="_blank"
                              data-gtm={footerNav}
                              rel="noopener noreferrer">
                              <img src={youtubeIcon} data-gtm={footerNav} alt={alt('YouTube Social Media Icon')} />
                           </a>
                        </div>
                        <div>
                           <a
                              href="https://www.instagram.com/bay_equity/"
                              target="_blank"
                              data-gtm={footerNav}
                              rel="noopener noreferrer">
                              <img src={instagramIcon} data-gtm={footerNav} alt={alt('Instagram Social Media Icon')} />
                           </a>
                        </div>
                     </div>
                  </>
               )}
            </div>
            <div className={styles.col3}>
               <h3>Contact Us</h3>
               <p className={styles.beImages}>
                  <img src={BEHouseMark} alt="" />
                  <img src={BELogoNoText} alt="" />
               </p>
               <address>
                  <a href={`tel:${phoneNumber}:`} data-gtm={footerNav}>
                     {phoneNumber}
                  </a>
                  <br />
                  <a
                     href="https://www.google.com/maps/place/770+Tamalpais+Dr+%23207,+Corte+Madera,+CA+94925/@37.926277,-122.519397,17z/data=!3m1!4b1!4m5!3m4!1s0x80859a8ce8b25d41:0x4af55620da9fad49!8m2!3d37.926277!4d-122.517203"
                     target="_blank"
                     data-gtm={footerNav}
                     rel="noopener noreferrer">
                     <span data-gtm={footerNav} className={styles.addLineHeight}>
                        770 Tamalpais Drive, Suite 207
                        <br />
                        Corte Madera,&nbsp;CA&nbsp;94925
                     </span>
                  </a>
               </address>
               <div className={styles.footerBtn}>
                  <LinkButton label="Get in Touch" href="/contact-us" dataGtm={footerNav} />
               </div>
            </div>
            <div className={styles.footerBottomRow}>
               <div>
                  <StaticText id="footer-text" items={staticTextItems} className={styles.footerStaticText} />
               </div>
               <div>
                  <img
                     className={styles.equalHousingLogo}
                     src={equalHousingLogo}
                     alt="Equal Housing Lender"
                     width="35"
                  />
               </div>
            </div>
         </footer>
         <OneTrustButton buttonRef={buttonRef} />
      </div>
   );
};

export default Footer;
