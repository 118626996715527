import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { HTMLContent } from '../Content';

export interface StaticTextItem {
   id: string;
   html: string;
   page: string;
   title: string;
   adminOrderBy: number;
}

interface StaticTextProps {
   id: string;
   items?: Array<StaticTextItem>;
   className?: string;
}

const StaticText = (props: StaticTextProps) => {
   const { id, items = [], className = '' } = props;

   // get static text data
   let staticTextData = [];

   if (items.length > 0) staticTextData = items;
   else {
      staticTextData = useStaticQuery(graphql`
         query StaticTextQuery {
            allStaticTextItems {
               id
               html
               title
               page
               adminOrderBy
            }
         }
      `).allStaticTextItems;
   }

   // get header static text
   const staticItem = staticTextData.find((item: StaticTextItem) => {
      return item.id === id;
   });
   const staticHtml = staticItem ? staticItem.html : '';

   return <HTMLContent content={staticHtml} className={className}></HTMLContent>;
};

export default StaticText;
