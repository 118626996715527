import React from 'react';
import styles from './styles.module.less';

interface LinkButtonProps {
   label: string;
   href: string;
   target?: string;
   dataGtm?: string;
}

const LinkButton = (props: LinkButtonProps) => {
   const { label, href, target, dataGtm } = props;
   return (
      <a className={styles.LinkButton} href={href} target={target} data-gtm={dataGtm}>
         {label}
      </a>
   );
};

export default LinkButton;
